import './EditMode.scss';

interface EditModeProps {
  onToggle: (isEnabled: boolean) => void;
}

export const EditMode: React.FC<EditModeProps> = ({ onToggle }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(event.target.checked);
  };

  return (
    <div className="columns is-mobile is-centered">
      <div className="column">
        <label
          htmlFor="switchExample"
          className="switch"
        >
          <input
            id="switchExample"
            type="checkbox"
            name="switchExample"
            onChange={handleChange}
          />
          <span className="slider" />
        </label>
      </div>
    </div>
  );
};
