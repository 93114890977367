import React, { createContext, useContext, useState, ReactNode } from 'react';

const EditModeContext = createContext<{
  isEditModeEnabled: boolean;
  toggleEditMode: () => void;
    }>({
      isEditModeEnabled: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      toggleEditMode: () => { }
    });

export const useEditMode = () => useContext(EditModeContext);

interface EditModeProviderProps {
  children: ReactNode;
}

export const EditModeProvider: React.FC<EditModeProviderProps> = ({ children }) => {
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const toggleEditMode = () => setIsEditModeEnabled((prev) => !prev);

  return (
    <EditModeContext.Provider value={{ isEditModeEnabled, toggleEditMode }}>
      {children}
    </EditModeContext.Provider>
  );
};
