import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import axios from 'axios';
import { errorMessages } from '../../services/firebaseErrors';  // import mapy błędów

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const auth = getAuth();

  const getErrorMessage = (errorCode: string): string => {
    return errorMessages[errorCode] || "Nieznany błąd, spróbuj ponownie.";
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        console.log("Zalogowano użytkownika:", userCredential.user);

        // Pobierz token Firebase
        const token = await userCredential.user.getIdToken();

        // Ustaw token w ciasteczku za pomocą zapytania do serwera
        await axios.post('/api/account/set-token', { token });

        setTimeout(() => {
          navigate('/');
        }, 500);
      } else {
        setError("Proszę zweryfikować adres e-mail przed zalogowaniem.");
        setTimeout(() => {
          navigate('/register');
        }, 1000);
      }
    } catch (errorInstance) {
      if (errorInstance instanceof FirebaseError) {
        setError(getErrorMessage(errorInstance.code));
      } else {
        setError("Logowanie nieudane. Spróbuj ponownie."); // Domyślny komunikat błędu
      }
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="field">
        <label htmlFor="" className="label">Email</label>
        <div className="control has-icons-left">
          <input
            type="email"
            placeholder="np. bobsmith@gmail.com"
            className="input"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            value={email}
            required
          />
          <span className="icon is-small is-left">
            <i className="fa fa-envelope"></i>
          </span>
        </div>
      </div>
      <div className="field">
        <label htmlFor="" className="label">Hasło</label>
        <div className="control has-icons-left">
          <input
            type="password"
            placeholder="******"
            className="input"
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            value={password}
            required
          />
          <span className="icon is-small is-left">
            <i className="fa fa-lock"></i>
          </span>
        </div>
      </div>
      {error && <div className="message is-danger m-4">
        <div className="message-body">
          {error}
        </div>
      </div>}
      <div className="field">
        <button className="button is-success" type="submit">Zaloguj się</button>
      </div>
      <div className="columns is-size-7 pt-3">
        <div className="column"><Link to="/password-reset">Nie pamiętasz hasła?</Link></div>
        <div className="column has-text-right">Pierwszy raz? <Link to="/register">Załóż konto</Link></div>
      </div>
    </form>
  );
};
