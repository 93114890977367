import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Products } from '../Products';
import 'bulma/css/bulma.min.css';
import './UserProductTabs.scss';
import { useNavigate } from 'react-router-dom';

interface Tab {
  name: string;
  catChild: string;
}

interface UserProductTabsProps {
  tabs: Tab[];
  catParent: string;
  activeTab: string;
}

export const UserProductTabs: React.FC<UserProductTabsProps> = ({ tabs, catParent, activeTab }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleTabChange = useCallback((catChild: string) => {
    if (activeTab === catChild) {
      setRefreshCount(prevCount => prevCount + 1);
    } else {
      navigate(`/products/${catChild}`);
    }
    setIsActive(false);
  }, [activeTab, navigate]);

  useEffect(() => {
    setLoading(false);
  }, [activeTab, refreshCount]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsActive(!isActive);

  const activeTabName = tabs.find(tab => tab.catChild === activeTab)?.name || 'Select Tab';

  return (
    <div className="content">
      <div className="columns is-centered">
        <div className="column is-full-mobile is-narrow-tablet">
          <div className="user-product-tabs-dropdown-container">
            <div className={`dropdown ${isActive ? 'is-active' : ''}`} ref={dropdownRef}>
              <div className="dropdown-trigger">
                <button 
                  className="button is-medium user-product-tabs-dropdown-button" 
                  aria-haspopup="true" 
                  aria-controls="dropdown-menu"
                  onClick={toggleDropdown}
                >
                  <span>{activeTabName}</span>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down pl-4" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {tabs.map(tab => (
                    <a
                      key={tab.catChild}
                      className={`dropdown-item user-product-tabs-dropdown-item ${activeTab === tab.catChild ? 'is-active' : ''}`}
                      onClick={() => handleTabChange(tab.catChild)}
                    >
                      {tab.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="spinner is-loading"></div>
      ) : (
        <Products
          key={`${activeTab}-${refreshCount}`}
          catParent={catParent}
          catChild={activeTab}
          onLoadingChange={setLoading}
        />
      )}
    </div>
  );
};