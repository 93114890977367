import { useState, ChangeEvent } from 'react';
import axios from 'axios';
import { UserData } from '../types/UserData';
import config from '../config';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

interface ManageUserDataReturn {
  formData: UserData;
  isLoading: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  handleInputChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  saveUserData: () => Promise<void>;
  uploadLogo: (file: File) => Promise<void>;
  handleDirectUpdate: (field: string, value: string | null) => void;
}

const useManageUserData = (initialData: UserData): ManageUserDataReturn => {
  const [formData, setFormData] = useState<UserData>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const apiUrl = config.API_URL;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDirectUpdate = (field: string, value: string | null) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value
    }));
  };
  

  const saveUserData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(`${apiUrl}/account/users/${formData.firebase_uid}`, formData);
      console.log('Data updated successfully:', response.data);
      setEditMode(false);
      toast.success('Dane zostały zaktualizowane pomyślnie!');
    } catch (error) {
      console.error("Error updating user data", error);
      toast.error('Nie udało się zaktualizować danych.');
    } finally {
      setIsLoading(false);
    }
  };

  const uploadLogo = async (file: File): Promise<void> => {
    const storage = getStorage();
    const storageRef = ref(storage, `users/${formData.firebase_uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      () => {
        setIsLoading(true);
      },
      (error) => {
        console.error("Error uploading file: ", error);
        toast.error('Nie udało się załadować logo.');
        setIsLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData(prevData => ({ ...prevData, company_logo: downloadURL }));
          setIsLoading(false);
          toast.success("Logo zostało załadowane pomyślnie! Kliknij 'Zapisz', aby potwierdzić.");
        });
      }
    );
  };


  return {
    formData,
    isLoading,
    editMode,
    setEditMode,
    handleInputChange,
    handleSelectChange,
    saveUserData,
    uploadLogo,
    handleDirectUpdate
  };
}

export default useManageUserData;
