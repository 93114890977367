import React, { useEffect, useState } from "react";
import "./TodayIs.scss";
import nameDays from "./imieniny.json"; // załóżmy, że JSON jest w folderze data

interface NameDay {
  day: number;
  month: number;
  names: string;
}

export const TodayIs: React.FC = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1; // Miesiące są zerowane, więc dodajemy 1
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Czyszczenie interwału po demontażu komponentu
    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = time.toLocaleTimeString();

  // Znalezienie dzisiejszych imienin
  const todayNames = nameDays.find(
    (entry: NameDay) => entry.day === day && entry.month === month
  )?.names;

  // Formatowanie daty
  const daysOfWeek = [
    "niedziela",
    "poniedziałek",
    "wtorek",
    "środa",
    "czwartek",
    "piątek",
    "sobota",
  ];
  const dayOfWeek = daysOfWeek[today.getDay()];
  const formattedDate = `${day.toString().padStart(2, "0")}.${month.toString().padStart(2, "0")}.${today.getFullYear()} r.`;

  return (
    <>
      <p>
        <span className="icon">
          <i className="fa-regular fa-calendar"></i>
        </span>
        <span>
          Dzisiaj jest <strong>{dayOfWeek}</strong>,
          {' '}
          <strong>{formattedDate}</strong>
        </span>
      </p>
      <div className="content">
        <h2 className="title has-text-weight-light is-2">
          {formattedTime}
        </h2>
      </div>
      <p>
        <span className="icon has-text-primary">
          <i className="fa-solid fa-gift"></i>
        </span>
        Najlepsze życzenia imieninowe dla<br />
        <span className="has-text-primary is-size-5">{todayNames ? todayNames.replace(/ /g, ", ") : "brak informacji"}.</span>
      </p>
    </>
  );
};
