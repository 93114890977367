import { Outlet } from "react-router-dom";
import "./App.scss";
import { EditModeProvider } from './context/EditModeContext';
import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts/fonts.css';

export const App = () => (
  <>
    <ToastContainer />
    <AuthProvider>
      <EditModeProvider>
        <Outlet />
      </EditModeProvider>
    </AuthProvider>
  </>
);
