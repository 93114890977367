import React, { useEffect } from "react";
import "./Products.scss";
import { UserProductTabs } from "../../components/UserProductTabs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const tabs = [
  { name: "Korpusy", catChild: "korpusy" },
  { name: "Blaty", catChild: "blaty" },
  { name: "Podnośniki", catChild: "podnosniki" },
  { name: "Zawiasy", catChild: "zawiasy" },
  { name: "Uchwyty", catChild: "uchwyty" },
  { name: "Szuflady", catChild: "szuflady" },
  { name: "Kosze cargo", catChild: "kosze-cargo" },
  { name: "Szkło i lustra", catChild: "szklo-i-lustra" },
  { name: "Oświetlenie i elektryka", catChild: "oswietlenie" },
  { name: "Fronty", catChild: "fronty" },
  { name: "Dodatki 1", catChild: "dodatkowe-1" },
  { name: "Dodatki 2", catChild: "dodatkowe-2" },
  { name: "Szafy", catChild: "szafy" },
];

export const Products: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!category) {
      navigate(`/products/${tabs[0].catChild}`, { replace: true });
    } else if (!tabs.some(tab => tab.catChild === category)) {
      navigate(`/products/${tabs[0].catChild}`, { replace: true });
    }
  }, [category, navigate]);

  if (!category) {
    return null;
  }

  return (
    <div className="main">
      <Helmet>
        <title>{`Edycja produktów | GoEstima`}</title>
        <meta name="description" content="Tutaj możesz edytować produkty systemowe i/lub dodać własne produkty. | GoEstima" />
        <link rel="canonical" href={`https://goestima.pl/products/${category}`} />
      </Helmet>
      <div className="has-text-centered pb-5">
        <h1 className="title">
          <span className="icon has-text-info m-3">
            <i className="fa-solid fa-couch"></i>
          </span>
          {' '}
          <span>Lista produktów</span>
        </h1>
        <p className="subtitle is-4">do tworzenia wycen</p>
      </div>
      <div className="content has-text-centered">
        <Link to="/addProduct" className="button is-info">
          <span className="icon">
            <i className="fa-solid fa-plus"></i>
          </span>
          <span>Dodaj nowy produkt</span>
        </Link>
      </div>
      <div className="content">
        <UserProductTabs catParent="meble" tabs={tabs} activeTab={category} />
      </div>
    </div>
  );
};
