// src\components\Header\Header.tsx
import { useTranslation } from 'react-i18next';
import './Header.scss';
import { Logo } from "./Logo";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { UserMenu } from "../UserMenu";
import { useUserData } from '../../hooks/useUserData';
import { UserLogo } from "../UserLogo";
import { currentVersion } from "../../utils/currentVersion";

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [userInfo] = useUserData();  // Destructuring array
  const location = useLocation();

  const isActive = (path: string): boolean => location.pathname === path;

  return (
    <header className="header columns is-vcentered">
      <div className="is-narrow header__logo column is-narrow">
        {userInfo?.company_logo ? (
          <UserLogo />
        ) : (
          <Logo />
        )}
      </div>
      <div className="header__center column has-text-centered">
        <div className="columns is-mobile is-centered">
          {currentUser ? (
            <>
              <div className="column is-narrow">
                <Link
                  to="/create-estimate"
                  className={`button ${isActive('/create-estimate') ? 'is-primary' : ''}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-folder-plus"></i>
                  </span>
                  <span>
                    Nowa wycena
                  </span>
                </Link>
              </div>
              <div className="column is-narrow">
                <Link
                  to="/estimate-furniture"
                  className={`button ${isActive('/estimate-furniture') ? 'is-primary' : ''}`}
                >
                  <span className="icon">
                    <i className="fa-regular fa-folder-open"></i>
                  </span>
                  <span>
                    Lista wycen
                  </span>
                </Link>
              </div>
            </>
          ) : (
            <>
              <p className="has-text-grey-lighter is-size-7">
                {currentVersion}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="is-narrow header__right column">
        {currentUser ? (
          <div className="columns is-mobile is-vcentered">
            <div className="column has-text-right">
              <UserMenu />
            </div>
          </div>
        ) : (
          <div className="columns is-mobile is-vcentered">
            <div className="column has-text-right has-text-centered-mobile">
              <Link to="/register">
                <button className="button is-info">
                  {t('header.register-link')}
                </button>
              </Link>
            </div>
            <div className="column has-text-left has-text-centered-mobile">
              <Link to="/login">
                <button className="button">
                  {t('header.login-link')}
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
