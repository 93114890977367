// src/pages/EstimateFurniture/EstimateSearch.tsx
import React from 'react';

interface EstimateSearchProps {
  searchTerm: string;
  onSearch: (searchTerm: string) => void;
}

export const EstimateSearch: React.FC<EstimateSearchProps> = ({ searchTerm, onSearch }) => {
  const clearSearchTerm = () => {
    onSearch('');
  };

  return (
    <div className="main">
      <div className="content">
        <h1 className="title has-text-centered">
          <span className="icon has-text-primary">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
          {' '}
          <span>Szukaj wyceny</span>
        </h1>
      </div>
      <div className="columns is-centered">
        <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop">
          <div className="field">
            <label className="label has-text-grey">Zacznij pisać</label>
            <div className="control has-icons-left has-icons-right">
              <input
                className="input is-medium search-input"
                type="text"
                placeholder="Szukaj wycen..."
                value={searchTerm}
                onChange={(e) => onSearch(e.target.value)}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
              </span>
              {searchTerm && (
                <span
                  className="icon is-small is-right clear-search"
                  onClick={clearSearchTerm}
                  style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                >
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
