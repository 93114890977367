import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import EstimateCategory from '../../components/EstimateCategory/EstimateCategory';
import config from '../../config';
import { useAuth } from '../../context/AuthContext';
import { EstimateSummary } from "../../components/EstimateSummary";
import { Helmet } from "react-helmet";

interface Estimate {
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  isLocked: boolean;
  id: number;
}

const validTabs = ["total", "korpusy", "blaty", "podnosniki", "zawiasy", "uchwyty", "szuflady", "kosze-cargo", "szklo-i-lustra", "oswietlenie", "fronty", "dodatkowe-1", "dodatkowe-2", "szafy"];

export const EstimateDetails: React.FC = () => {
  const { estimateId } = useParams<{ estimateId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [estimate, setEstimate] = useState<Estimate | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("korpusy");
  const [isLoading, setIsLoading] = useState(true);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    const path = location.pathname.split('/');
    const tab = path[path.length - 1];

    if (tab && tab !== estimateId && validTabs.includes(tab)) {
      setActiveTab(tab);
    } else {
      setActiveTab("korpusy");
      navigate(`/estimate-furniture/${estimateId}/korpusy`, { replace: true });
    }
  }, [location, estimateId, navigate]);

  const fetchEstimateDetails = useCallback(async () => {
    if (estimateId && currentUser) {
      try {
        const response = await axios.get(`${config.API_URL}/estimates/${estimateId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        console.log('Surowa odpowiedź z API:', response.data);

        if (response.data) {
          const fetchedEstimate = {
            ...response.data,
            isLocked: response.data.isLocked === true
          };
          setEstimate(fetchedEstimate);
          setIsLocked(fetchedEstimate.isLocked);
          console.log('Ustawiono isLocked na:', fetchedEstimate.isLocked);
        } else {
          console.error('No data returned for estimate');
          navigate('/estimate-furniture');
        }
      } catch (error) {
        console.error('Error fetching estimate details:', error);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          console.error('Estimate not found or user has no access.');
          navigate('/estimate-furniture');
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [estimateId, currentUser, navigate]);

  useEffect(() => {
    fetchEstimateDetails();
  }, [fetchEstimateDetails]);

  const handleChange = (field: 'name' | 'description', value: string) => {
    if (estimate) {
      setEstimate({ ...estimate, [field]: value });
    }
  };

  const handleToggleLock = async () => {
    if (estimateId && currentUser) {
      try {
        const response = await axios.put(`${config.API_URL}/estimates/${estimateId}/toggle-lock`, {}, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        console.log('Odpowiedź z toggle lock:', response.data);

        if (response.data.success) {
          const newLockedState = response.data.isLocked === true;
          setIsLocked(newLockedState);
          setEstimate(prev => prev ? { ...prev, isLocked: newLockedState } : null);
          console.log('Zaktualizowano isLocked na:', newLockedState);
        }
      } catch (error) {
        console.error('Error toggling estimate lock:', error);
      }
    }
  };

  const handleSave = async () => {
    if (estimateId && currentUser && estimate) {
      try {
        await axios.put(`${config.API_URL}/estimates/${estimateId}`,
          estimate,
          {
            headers: {
              Authorization: `Bearer ${await currentUser.getIdToken()}`
            }
          }
        );
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating estimate:', error);
      }
    }
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    navigate(`/estimate-furniture/${estimateId}/${tab}`);
  };

  const refreshEstimateDetails = useCallback(async () => {
    await fetchEstimateDetails();
  }, [fetchEstimateDetails]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!estimate) {
    return <div>No estimate data available.</div>;
  }

  console.log('Stan przed renderowaniem - isLocked:', isLocked, 'estimate:', estimate);

  return (
    <div className="content">
      <Helmet>
        <title>{`Wycena dla ${estimate.name} | GoEstima`}</title>
        <meta name="description" content="Kalkulator mebli na wymiar" />
        <link rel="canonical" href={`https://goestima.pl/estimate-furniture/${estimate.id}`} />
      </Helmet>
      <div className="estimate-header has-text-centered">
        <h1 className="title">
          <span className="icon has-text-primary m-3">
            <i className="fa-regular fa-folder-open"></i>
          </span>
          {' '}
          Wycena
          {' '}
          <span className="has-text-primary">
            {estimate.name}
          </span>
        </h1>
        <div className="subtitle is-6">
          {estimate.description ? (
            <p>{estimate.description}</p>
          ) : (
            <p className="pt-1"></p>
          )}
        </div>
        <div className="content">
          <button
            className={`button ${isEditing ? 'is-danger' : 'is-primary'}`}
            onClick={() => setIsEditing(!isEditing)}
            disabled={isLocked}
          >
            <span className="icon">
              <i className={`fas ${isEditing ? 'fa-times' : 'fa-edit'}`}></i>
            </span>
            <span>{isEditing ? 'Ukryj formularz' : 'Edytuj nazwę'}</span>
          </button>
          <button
            className={`button ml-2 ${isLocked ? 'is-danger' : ''}`}
            onClick={handleToggleLock}
            title={estimate.isLocked ? "Odblokuj wycenę" : "Zablokuj wycenę"}
          >
            <span className="icon">
              <i className={`fas ${isLocked ? 'fa-lock' : 'fa-lock-open'}`}></i>
            </span>
          </button>
        </div>
        {isLocked && (
          <div className="content has-text-danger">
            Wycena jest zablokowana.
          </div>
        )}
      </div>

      <div className="columns">
        <div className={`pb-0 column is-half is-offset-one-quarter estimate-details-form ${isEditing ? 'is-active mb-5 p-5 mt-5' : ''}`}>
          <div className="field">
            <label className="label">Nazwa wyceny</label>
            <div className="control">
              <input
                className="input"
                type="text"
                value={estimate.name}
                onChange={(e) => handleChange('name', e.target.value)}
                maxLength={250}
                placeholder="Jan Kowalski - meble kuchenne"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Krótki opis wyceny</label>
            <div className="control">
              <textarea
                rows={2}
                className="textarea"
                value={estimate.description}
                onChange={(e) => handleChange('description', e.target.value)}
                maxLength={255}
                placeholder="ul. Mickiewicza 15, 33-435 Meblowo, tel. 556 667 778"
              />
            </div>
          </div>
          <button className="button is-primary" onClick={handleSave}>Zapisz</button>
        </div>
      </div>

      <div className="buttons-container mb-2">
        {[
          { id: "total", label: "Podsumowanie" },
          { id: "korpusy", label: "Korpusy" },
          { id: "blaty", label: "Blaty" },
          { id: "podnosniki", label: "Podnośniki" },
          { id: "zawiasy", label: "Zawiasy" },
          { id: "uchwyty", label: "Uchwyty" },
          { id: "szuflady", label: "Szuflady" },
          { id: "kosze-cargo", label: "Kosze cargo" },
          { id: "szklo-i-lustra", label: "Szkło i lustra" },
          { id: "oswietlenie", label: "Oświetlenie i elektryka" },
          { id: "fronty", label: "Fronty" },
          { id: "dodatkowe-1", label: "Dodatki 1" },
          { id: "dodatkowe-2", label: "Dodatki 2" },
          { id: "szafy", label: "Szafy" },

        ].map(tab => (
          <button
            key={tab.id}
            className={`button is-normal ${activeTab === tab.id ? 'is-link' : ''} ${tab.id === 'total' ? 'sum is-warning mr-5' : ''}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.id === 'total' && (
              <span className="icon">
                <i className="fa-solid fa-coins"></i>
              </span>
            )}
            <span>
              {tab.label}
            </span>
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === "korpusy" && <EstimateCategory category="korpusy" categoryName="Korpusy" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "blaty" && <EstimateCategory category="blaty" categoryName="Blaty" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "podnosniki" && <EstimateCategory category="podnosniki" categoryName="Podnośniki" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "zawiasy" && <EstimateCategory category="zawiasy" categoryName="Zawiasy" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "uchwyty" && <EstimateCategory category="uchwyty" categoryName="Uchwyty" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "szuflady" && <EstimateCategory category="szuflady" categoryName="Szuflady" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "kosze-cargo" && <EstimateCategory category="kosze-cargo" categoryName="Kosze cargo" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "szklo-i-lustra" && <EstimateCategory category="szklo-i-lustra" categoryName="Szkło i lustra" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "oswietlenie" && <EstimateCategory category="oswietlenie" categoryName="Oświetlenie i elektryka" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "fronty" && <EstimateCategory category="fronty" categoryName="Fronty" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "dodatkowe-1" && <EstimateCategory category="dodatkowe-1" categoryName="Dodatki 1" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "dodatkowe-2" && <EstimateCategory category="dodatkowe-2" categoryName="Dodatki 2" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "szafy" && <EstimateCategory category="szafy" categoryName="Szafy" estimateId={Number(estimateId)} onProductUpdate={refreshEstimateDetails} isLocked={isLocked} />}
        {activeTab === "total" && (
          <EstimateSummary
            estimateId={Number(estimateId)}
            estimateName={estimate.name}
            estimateDescription={estimate.description}
            createdAt={estimate.created_at}
            updatedAt={estimate.updated_at}
          />
        )}
      </div>
    </div >
  );
};
