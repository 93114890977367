import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import { useReactToPrint } from 'react-to-print';
import logoImage from '../../assets/images/logo.png';
import { useUserData } from '../../hooks/useUserData';
import './print-styles.scss';
import './EstimateSummary.scss';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link } from "react-router-dom";
import { getConditionalUrl } from '../../utils/cdnHelper';

interface CategoryTotal {
  category: string;
  total: number;
  products: Array<{
    name: string;
    quantity: number;
    price: number;
    unit: string;
    width: number | null;
    height: number | null;
    priceOption?: string;
    isDeleted: boolean;
  }>;
}

interface SummaryProps {
  estimateId: number;
  estimateName: string;
  estimateDescription: string;
  createdAt: string;
  updatedAt: string;
}

const categoryTranslations: { [key: string]: string } = {
  'podnosniki': 'Podnośniki',
  'korpusy': 'Korpusy',
  'blaty': 'Blaty',
  'zawiasy': 'Zawiasy',
  'uchwyty': 'Uchwyty',
  'szuflady': 'Szuflady',
  'kosze-cargo': 'Kosze cargo',
  'szklo-i-lustra': 'Szkło i lustra',
  'oswietlenie': 'Oświetlenie i elektryka',
  'fronty': 'Fronty',
  'dodatkowe-1': 'Dodatkowe 1',
  'dodatkowe-2': 'Dodatkowe 2',
  'szafy': 'Szafy',
};

type PriceDisplayOption = 'all' | 'categoryOnly' | 'totalOnly';

export const EstimateSummary: React.FC<SummaryProps> = ({ estimateId, estimateName, estimateDescription, createdAt, updatedAt }) => {
  const { currentUser } = useAuth();
  const [categorySummaries, setCategorySummaries] = useState<CategoryTotal[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const componentRef = useRef(null);
  const [userInfo] = useUserData();
  const [priceDisplayOption, setPriceDisplayOption] = useState<PriceDisplayOption>('totalOnly');
  const [discount, setDiscount] = useState<number>(0);
  const [showDiscountInput, setShowDiscountInput] = useState<boolean>(false);
  const [discountedPrice, setDiscountedPrice] = useState<number>(0);
  const [discountInput, setDiscountInput] = useState<string>('');
  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState<boolean>(false);

  const fetchSummary = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get(`${config.API_URL}/estimate-products/${estimateId}/summary`, {
        headers: {
          Authorization: `Bearer ${await currentUser.getIdToken()}`
        }
      });

      setCategorySummaries(response.data.categorySummaries.map((category: CategoryTotal) => ({
        ...category,
        category: categoryTranslations[category.category] || category.category,
        total: Number(category.total) || 0,
        products: category.products.map(product => ({
          ...product,
          quantity: Number(product.quantity) || 0,
          price: Number(product.price) || 0,
          width: product.width !== undefined ? Number(product.width) : null,
          height: product.height !== undefined ? Number(product.height) : null,
          priceOption: product.priceOption
        }))
      })));
      setTotalPrice(Number(response.data.totalPrice) || 0);
    } catch (error) {
      console.error('Nie udało się pobrać podsumowania:', error);
    } finally {
      setLoading(false);
    }
  }, [estimateId, currentUser]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const calculateDiscount = useCallback(() => {
    const discountAmount = totalPrice * (discount / 100);
    setDiscountedPrice(totalPrice - discountAmount);
  }, [totalPrice, discount]);

  const updateDiscountInDatabase = useCallback(async (newDiscount: number) => {
    if (!currentUser) return;
    setIsUpdatingDiscount(true);
    try {
      const response = await axios.put(`${config.API_URL}/estimates/${estimateId}/discount`,
        { discount: newDiscount },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );
      if (response.status === 200) {
        setDiscount(newDiscount);
        setDiscountInput(newDiscount.toString()); // Ustawiamy jako string
        calculateDiscount();
        // Tutaj możesz dodać powiadomienie o sukcesie
      }
    } catch (error) {
      console.error('Error updating discount:', error);
      // Tutaj możesz dodać powiadomienie o błędzie
      setDiscount(discount); // Przywróć poprzednią wartość
      setDiscountInput(discount.toString()); // Ustawiamy jako string
    } finally {
      setIsUpdatingDiscount(false);
      setShowDiscountInput(false);
    }
  }, [estimateId, currentUser, calculateDiscount, discount]);

  useEffect(() => {
    calculateDiscount();
  }, [calculateDiscount]);

  useEffect(() => {
    const fetchEstimateDetails = async () => {
      if (!currentUser) return;
      try {
        const response = await axios.get(`${config.API_URL}/estimates/${estimateId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        if (response.data.discount !== undefined) {
          const discountValue = parseFloat(response.data.discount);
          setDiscount(discountValue);
          setDiscountInput(discountValue.toString()); // Ustawiamy jako string bez formatowania
        }
      } catch (error) {
        console.error('Error fetching estimate details:', error);
      }
    };

    fetchEstimateDetails();
  }, [estimateId, currentUser]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `,
    documentTitle: `Wycena - ${estimateName}`,
  });

  const handleDiscountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Pozwalamy na puste pole lub liczby całkowite
    if (value === '' || /^\d+$/.test(value)) {
      setDiscountInput(value);
    }
  };

  const handleDiscountSubmit = () => {
    const newDiscount = discountInput === '' ? 0 : Math.min(100, parseInt(discountInput, 10));
    updateDiscountInDatabase(newDiscount);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Zapobiega domyślnej akcji formularza
      handleDiscountSubmit();
    }
  };

  const handleSavePDF = useCallback(async () => {
    if (componentRef.current) {
      const canvas = await html2canvas(componentRef.current, {
        scale: 2,
        logging: false,
        useCORS: true
      });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Wycena - ${estimateName}.pdf`);
    }
  }, [estimateName]);

  const formatQuantity = (product: CategoryTotal['products'][0]): string => {
    if (product.width && product.height) {
      const area = (product.width * product.height) / 10000;
      return `${area.toFixed(2)} m²`;
    }
    if (product.unit === 'szt') {
      return `${product.quantity.toFixed(0)} ${product.unit}`;
    }
    return `${product.quantity.toFixed(2)} ${product.unit}`;
  };

  if (loading) {
    return <div className="spinner is-loading"></div>;
  }

  const SummaryHeader = () => (
    <div className="columns print-header">
      <div className="column">
        <p>Wycena przygotowana dla:</p>
        <h2 className="summary-title mt-3"><span className="has-text-info">{estimateName}</span></h2>
        <p className="mt-3">{estimateDescription}</p>
        <p className="mt-3"><strong>Utworzona:</strong> {new Date(createdAt).toLocaleString()}</p>
        <p><strong>Aktualizacja:</strong> {new Date(updatedAt).toLocaleString()}</p>
      </div>
      <div className="column print-logo-column">
        {userInfo?.company_logo ? (
          <img src={getConditionalUrl(userInfo.company_logo)} alt="Logo firmy" id="companyLogo" />
        ) : (
          <img src={logoImage} alt="GoEstima" id="companyLogo" />
        )}
        <p className="mt-3">
          <span className="icon-text">
            <span>Wydrukowano: {new Date().toLocaleString()}</span>
          </span>
        </p>
        {userInfo?.company_name && (
          <p className="mt-4">
            <span>{userInfo.company_name}</span>
          </p>
        )}
        {userInfo?.address_line && (
          <p className="mt-2">
            <span>{userInfo.address_line}</span>
          </p>
        )}
        {(userInfo?.zipcode_postal || userInfo?.city) && (
          <p className="mt-2">
            <span>
              {userInfo.zipcode_postal && `${userInfo.zipcode_postal} `}
              {userInfo.city}
            </span>
          </p>
        )}
        {userInfo?.company_nip && (
          <p className="mt-2">
            <span>NIP: {userInfo.company_nip}</span>
          </p>
        )}
        {userInfo?.phone_number && (
          <p className="mt-2">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-phone"></i>
              </span>
              <span>{userInfo.phone_number}</span>
            </span>
          </p>
        )}
        {userInfo?.email && (
          <p className="mt-2">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-envelope"></i>
              </span>
              <span>{userInfo.email}</span>
            </span>
          </p>
        )}
      </div>
    </div>
  );

  const ProductItem: React.FC<{ product: CategoryTotal['products'][0] }> = ({ product }) => {
    const textColorClass = product.isDeleted ? 'has-text-danger' : '';

    return (
      <div className={`column p-0 has-text-left ${textColorClass}`}>
        <span className="product-name">
          {product.name}
        </span>
        {product.priceOption && product.priceOption.trim() !== '' && (
          <span className="product-option">
            <br />
            {`Opcja: ${product.priceOption}`}
          </span>
        )}
        <span className="product-quantity">
          {' - '}
          <span className="has-text-weight-semibold">
            {formatQuantity(product)}
          </span>
        </span>
        {priceDisplayOption === 'all' && (
          <span className="product-price">
            <br />
            <span className={`has-text-weight-semibold ${product.isDeleted ? '' : 'has-text-info'}`}>
              {`${product.price.toFixed(0)} zł`}
            </span>
          </span>
        )}
        {product.isDeleted && (
          <span className="deleted-indicator">
            <br />
            <span className="icon-text">
              <span className="icon">
                <i className="fa-solid fa-triangle-exclamation"></i>
              </span>
              <span>Produkt archiwalny</span>
            </span>
          </span>
        )}
      </div>
    );
  };

  const SummaryDetails = () => {
    const priceDifference = totalPrice - discountedPrice;

    if (categorySummaries.length === 0 || categorySummaries.every(category => category.products.length === 0)) {
      return (
        <div className="summary-details">
          <div className="column is-full has-text-centered">
            <p className="is-size-5 has-text-grey-light">
              <span className="icon">
                <i className="fas fa-info-circle"></i>
              </span>
              {' '}
              Ta wycena jest pusta. Nie dodano jeszcze żadnych produktów.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="summary-details">
        <div className="columns">
          <div className="column is-narrow">
            <h2 className="has-text-info">
              <span className="icon">
                <i className="fa-solid fa-coins"></i>
              </span>
              {' '}
              <span>Suma: {' '}</span>
              {discount > 0 ? (
                <>
                  <span className="has-text-info">
                    {discountedPrice.toFixed(0)} zł
                  </span>
                  {' '}
                  <span className="has-text-grey pl-3" style={{ textDecoration: 'line-through' }}>
                    {totalPrice.toFixed(0)} zł
                  </span>
                </>
              ) : (
                <span className="has-text-info">{totalPrice.toFixed(0)} zł</span>
              )}
            </h2>
            {discount > 0 && (
              <div>
                <span className="has-text-weight-bold has-text-danger">
                  Rabat {discount}% na całość.
                </span>
                <br />
                <span className="has-text-weight-bold has-text-danger">
                  Oszczędzasz {priceDifference.toFixed(0)} zł.
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="columns is-multiline mt-5">
          {categorySummaries
            .filter(category => category.products.length > 0)
            .map((category) => (
              <div key={category.category} className="column is-3-fullhd is-3-widescreen is-4-desktop is-6-tablet is-12-mobile">
                <h4>
                  <span className="icon has-text-link">
                    <i className="fas fa-check"></i>
                  </span>
                  {' '}
                  <span>
                    <Link to={`/estimate-furniture/${estimateId}/${Object.keys(categoryTranslations).find(key => categoryTranslations[key] === category.category) || category.category}`}>
                      {category.category}
                    </Link>
                    {(priceDisplayOption === 'all' || priceDisplayOption === 'categoryOnly') &&
                      ` - ${category.total.toFixed(2)} zł`}
                  </span>
                </h4>
                <div className="content">
                  {category.products.map((product, index) => (
                    <ProductItem key={index} product={product} />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="box content has-text-centered has-background-gradient-blue py-4">
        <h2 className="title is-4 has-text-white is-flex is-align-items-center is-justify-content-center m-0">
          <span className="icon mr-2">
            <i className="fas fa-clipboard-list"></i>
          </span>
          <span>{estimateName}</span>
        </h2>
      </div>

      <div className="no-print">
        <div className="columns is-multiline is-mobile is-vcentered">
          <div className="column is-full-mobile is-flex is-justify-content-center has-text-centered">
            <div>
              <label className="label">Opcje wyświetlania cen:</label>
              <div className="select">
                <select
                  value={priceDisplayOption}
                  onChange={(e) => setPriceDisplayOption(e.target.value as PriceDisplayOption)}
                >
                  <option value="totalOnly">Suma całkowita</option>
                  <option value="categoryOnly">Sumy kategorii</option>
                  <option value="all">Wszystkie ceny</option>
                </select>
              </div>
            </div>
          </div>

          <div className="column is-full-mobile">
            <div className="buttons is-centered">
              <div className="button-container">
                {showDiscountInput ? (
                  <div className="field has-addons">
                    <div className="control">
                      <input
                        className="input"
                        style={{ width: '114px' }}
                        type="text"
                        value={discountInput}
                        onChange={handleDiscountInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Rabat %"
                        disabled={isUpdatingDiscount}
                      />
                    </div>
                    <div className="control">
                      <button
                        className={`button is-info ${isUpdatingDiscount ? 'is-loading' : ''}`}
                        onClick={handleDiscountSubmit}
                        disabled={isUpdatingDiscount}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="button is-primary"
                    onClick={() => {
                      setShowDiscountInput(true);
                      setDiscountInput(discount > 0 ? discount.toString() : '');
                    }}
                  >
                    <span className="icon is-small is-left">
                      <i className="fa-solid fa-percent"></i>
                    </span>
                    <span>Rabat {discount > 0 ? `(${discount}%)` : ''}</span>
                  </button>
                )}
              </div>

              <button className="button is-warning" onClick={handlePrint}>
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-print"></i>
                </span>
                <span>Drukuj wycenę</span>
              </button>

              <button className="button is-info" onClick={handleSavePDF}>
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-file-pdf"></i>
                </span>
                <span>Zapisz PDF</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="has-background-gradient-grey box columns content mt-6 mb-6 estimate m-1">
        <div className="column summary-container" ref={componentRef}>
          <div className="summary-content-web">
            <div className="columns mb-4">
              <div className="column">
                <h2 className="summary-title">{estimateName}</h2>
                <p>{estimateDescription}</p>
              </div>
              <div className="column has-text-right">
                <p><strong>Utworzona:</strong> {new Date(createdAt).toLocaleString()}</p>
                <p><strong>Aktualizacja:</strong> {new Date(updatedAt).toLocaleString()}</p>
              </div>
            </div>
            <SummaryDetails />
          </div>
          <div className="summary-content-print">
            <SummaryHeader />
            <SummaryDetails />
          </div>
        </div>
      </div>
    </>
  );
};