import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import './EstimateCategory.scss';
import { Header, SortDropdown, ProductList } from '../EstimateProduct';
import { Product } from '../../types/product';
import { CommonItemsProps } from '../../types/commonTypes';

type ItemsProps = CommonItemsProps;

interface ProductCategoryProps {
  category: string;
  categoryName: string;
  estimateId: number | null;
  onProductUpdate: () => void;
  isLocked: boolean;
}

interface EstimateProduct {
  id: number;
  estimate_id: number;
  product_id: number;
  quantity: number;
  width: number | null;
  height: number | null;
  total_price: number;
  purchase_price: number | null;
  selling_price: number | null;
  option_id: number | null;
}

export const EstimateCategory: React.FC<ProductCategoryProps> = ({
  category,
  categoryName,
  estimateId,
  onProductUpdate,
  isLocked,
}) => {
  const { currentUser } = useAuth();
  const [items, setItems] = useState<ItemsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [sortKey, setSortKey] = useState<'name' | 'id' | 'sellingPrice'>('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [categoryTotalPrice, setCategoryTotalPrice] = useState<number>(0);
  const [savedAreas, setSavedAreas] = useState<{ [key: string]: number }>({});
  const [totalArea, setTotalArea] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const fetchSavedAreas = useCallback(() => {
    console.log(`fetchSavedAreas called. Category: ${category}, EstimateId: ${estimateId}`);
    if (category === 'fronty' && estimateId) {
      let sum = 0;
      const areas: { [key: string]: number } = {};
      console.log('Checking localStorage keys:');
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        console.log(`Checking key: ${key}`);
        if (key && key.startsWith(`area.${estimateId}.`)) { // Zmiana tutaj: używamy kropki
          const productId = key.split('.')[2]; // Zmiana tutaj: używamy kropki
          const savedArea = localStorage.getItem(key);
          console.log(`Found matching key: ${key}, value: ${savedArea}, productId: ${productId}`);
          if (savedArea && productId) {
            const areaValue = parseFloat(savedArea);
            if (!isNaN(areaValue)) {
              areas[productId] = areaValue;
              sum += areaValue;
              console.log(`Added area for product ${productId}: ${areaValue}`);
            } else {
              console.log(`Invalid area value for product ${productId}: ${savedArea}`);
            }
          } else {
            console.log(`Invalid data for key ${key}: savedArea=${savedArea}, productId=${productId}`);
          }
        }
      }
      console.log('Final saved areas:', areas);
      console.log('Total area:', sum);
      setSavedAreas(areas);
      setTotalArea(sum);
    } else {
      console.log(`Not fetching saved areas: category=${category}, estimateId=${estimateId}`);
    }
  }, [category, estimateId]);

  useEffect(() => {
    fetchSavedAreas();
  }, [fetchSavedAreas]);

  useEffect(() => {
    console.log('Component rendered. Category:', category, 'SavedAreas:', savedAreas);
  }, [category, savedAreas]);

  const clearOldData = useCallback(() => {
    if (estimateId) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(`area.`) && !key.startsWith(`area.${estimateId}.`)) {
          localStorage.removeItem(key);
          console.log(`Removed old data: ${key}`);
        }
      }
    }
  }, [estimateId]);

  useEffect(() => {
    clearOldData();
    fetchSavedAreas();
  }, [clearOldData, fetchSavedAreas]);

  const fetchItems = useCallback(async () => {
    const firebaseUid = currentUser ? currentUser.uid : 'master';
    const url = `${config.API_URL}/products/category/meble/${category}/${firebaseUid}`;
    console.log('Fetching from URL:', url);

    try {
      const { data: fetchedItems } = await axios.get<Product[]>(url);
      console.log('Fetched items from API:', fetchedItems);

      const estimateProductsPromise = estimateId
        ? axios.get<EstimateProduct[]>(`${config.API_URL}/estimate-products/${estimateId}/category/${category}`)
        : Promise.resolve({ data: [] });

      const [estimateProductsResponse] = await Promise.all([estimateProductsPromise]);

      const estimateProducts = estimateProductsResponse.data;
      console.log('Estimate products:', estimateProducts);

      const allRelevantProductIds = new Set([
        ...fetchedItems.map(item => item.id),
        ...estimateProducts.map(ep => ep.product_id)
      ]);

      const customItems = await Promise.all(Array.from(allRelevantProductIds).map(async (productId: number) => {
        let item = fetchedItems.find(fi => fi.id === productId);
        const estimateProduct = estimateProducts.find(ep => ep.product_id === productId);

        if (!item && estimateProduct) {
          // Produkt jest w wycenie, ale nie ma go w fetchedItems (prawdopodobnie jest wyłączony)
          try {
            const deletedProductResponse = await axios.get(`${config.API_URL}/products/${productId}`);
            item = {
              ...deletedProductResponse.data,
              is_active: true // Ustawiamy na true, bo jest używany w wycenie
            };
          } catch (error) {
            console.error(`Failed to fetch product ${productId}:`, error);
            return null;
          }
        } else if (item && !estimateProduct && item.is_active === false) {
          // Produkt jest wyłączony i nie jest używany w wycenie
          return null;
        }

        if (!item) {
          return null;
        }

        // Reszta kodu pozostaje bez zmian
        const matchedProducts = estimateProducts.filter(ep => ep.product_id === productId);
        const totalPrice = matchedProducts.reduce((sum, ep) => sum + parseFloat(ep.total_price.toString()), 0);

        let productOptions = [];
        try {
          const optionsResponse = await axios.get(`${config.API_URL}/products/${productId}/options/${firebaseUid}`);
          productOptions = optionsResponse.data;
        } catch (error) {
          console.error(`Failed to fetch options for product ${productId}:`, error);
        }

        return {
          ...item,
          id: productId.toString(),
          customSellingPrice: item?.sellingPrice?.toString() || '0',
          quantity: matchedProducts.reduce((sum, ep) => sum + parseFloat(ep.quantity.toString()), 0),
          width: matchedProducts[0]?.width ?? null,
          height: matchedProducts[0]?.height ?? null,
          option_id: matchedProducts[0]?.option_id ?? null,
          total_price: totalPrice.toString(),
          is_active: estimateProduct ? true : item.is_active,
          desc: item?.description || item?.title || 'Brak opisu',
          options: productOptions,
          isArchived: item?.is_deleted || false,
          isActive: item?.is_active || false,
        } as ItemsProps;
      }));

      console.log('Custom items:', customItems);

      setItems(customItems.filter(item => item !== null) as ItemsProps[]);
    } catch (fetchError) {
      console.error('Failed to fetch items:', fetchError);
      setError('Failed to fetch items');
    } finally {
      setLoading(false);
    }
  }, [currentUser, category, estimateId]);

  const fetchCategoryTotal = useCallback(async () => {
    if (estimateId) {
      try {
        const response = await axios.get(`${config.API_URL}/estimate-products/category-total/${estimateId}/${category}`);
        const totalPrice = parseFloat(response.data.totalPrice);
        setCategoryTotalPrice(isNaN(totalPrice) ? 0 : totalPrice);
      } catch (error) {
        console.error('Error fetching category total:', error);
        setCategoryTotalPrice(0);
      }
    }
  }, [estimateId, category]);

  useEffect(() => {
    fetchItems();
    fetchCategoryTotal();
  }, [fetchItems, fetchCategoryTotal]);

  const handleProductUpdate = useCallback(() => {
    fetchItems();
    fetchCategoryTotal();
    onProductUpdate(); // Przekaż estimateId
  }, [fetchItems, fetchCategoryTotal, onProductUpdate]);

  const handleAddProduct = useCallback(async (productId: string) => {
    if (estimateId) {
      try {
        const product = items.find(item => item.id === productId);
        if (product) {
          const { id: productId, sellingPrice, purchasePrice } = product;

          const payload = {
            estimate_id: estimateId,
            product_id: productId,
            quantity: 1,
            width: null,
            height: null,
            total_price: parseFloat(sellingPrice.toString()),
            purchase_price: parseFloat(purchasePrice.toString()),
            selling_price: sellingPrice,
          };

          console.log('Payload:', payload);

          await axios.post(`${config.API_URL}/estimate-products`, payload);
          handleProductUpdate();
        }
      } catch (error) {
        console.error('Error adding product to estimate:', error);
      }
    }
  }, [estimateId, items, handleProductUpdate]);

  const handleRemoveProduct = useCallback(async (productId: string) => {
    if (estimateId) {
      try {
        await axios.delete(`${config.API_URL}/estimate-products/${productId}`);
        handleProductUpdate();
      } catch (error) {
        console.error('Error removing product from estimate:', error);
      }
    }
  }, [estimateId, handleProductUpdate]);

  const handleSortChange = (key: 'name' | 'id' | 'sellingPrice', order: 'asc' | 'desc') => {
    setSortKey(key);
    setSortOrder(order);
    setDropdownActive(false);
  };

  const sortedItems = [...items.filter(item => item.extra === 0)].sort((a, b) => {
    let comparison = 0;
    if (sortKey === 'name') {
      comparison = a.title.localeCompare(b.title);
    } else if (sortKey === 'id') {
      comparison = parseInt(a.id) - parseInt(b.id);
    } else if (sortKey === 'sellingPrice') {
      const priceA = parseFloat(a.customSellingPrice || a.sellingPrice.toString());
      const priceB = parseFloat(b.customSellingPrice || b.sellingPrice.toString());
      comparison = priceA - priceB;
    }

    return sortOrder === 'asc' ? comparison : -comparison;
  });

  const extraItems = items.filter(item => item.extra === 1);

  const filteredItems = useCallback((items: ItemsProps[]) => {
    return items.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.desc && item.desc.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm]);

  const filteredSortedItems = filteredItems(sortedItems);
  const filteredExtraItems = filteredItems(extraItems);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const totalCategory = parseFloat((categoryTotalPrice.toFixed(2)));

  return (
    <div className="content">
      <div className="column is-half is-offset-one-quarter mb-5 search-container">
        <div className="field">
          <label className="label has-text-grey">Zacznij pisać</label>
          <div className="control has-icons-left has-icons-right">
            <input
              className="input is-medium search-input"
              type="text"
              placeholder={`Szukaj produktów... w kategorii ${categoryName}`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span className="icon is-left search-icon">
              <i className="fas fa-search"></i>
            </span>
            {searchTerm && (
              <span
                className="icon is-right clear-search"
                onClick={clearSearchTerm}
                style={{ pointerEvents: 'auto', zIndex: 3 }}
              >
                <i className="fas fa-times"></i>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column">
          {filteredSortedItems.length > 0 && (
            <Header
              title={categoryName}
              category={category}
              total={totalArea}
              iconClass="fa-solid fa-check"
            />
          )}
        </div>
        <div className="column has-text-centered">
          <h2 className="title is-5">
            {totalCategory > 0 && (
              <>
                <span className="icon">
                  <i className="fa-solid fa-coins"></i>
                </span>
                {' '}
                Razem: {totalCategory} zł
              </>
            )}
          </h2>
        </div>
        <div className="column has-text-right is-hidden-mobile">
          <SortDropdown
            sortKey={sortKey}
            sortOrder={sortOrder}
            onSortChange={handleSortChange}
            dropdownActive={dropdownActive}
            setDropdownActive={setDropdownActive}
          />
        </div>
      </div>

      {items.length === 0 ? (
        <div className="m-6 has-text-centered">
          <p className="is-size-4">
            <span className="icon">
              <i className="fas fa-info-circle"></i>
            </span>
            {' '}
            Brak produktów w tej kategorii.
          </p>        </div>
      ) : searchTerm && filteredSortedItems.length === 0 && filteredExtraItems.length === 0 ? (
        <div className="m-6 has-text-centered">
          <p className="is-size-4">
            <span className="icon">
              <i className="fas fa-info-circle"></i>
            </span>
            {' '}
            Nie znalezniono produktów.
          </p>
        </div>
      ) : (
        <>
          {estimateId && (
            <ProductList
              items={filteredSortedItems}
              estimateId={estimateId}
              onAddProduct={handleAddProduct}
              onRemoveProduct={handleRemoveProduct}
              onProductUpdate={handleProductUpdate}
              isLocked={isLocked}
              category={category}
            />
          )}
          {filteredExtraItems.length > 0 && estimateId && (
            <div>
              <Header
                title={`Dodatki do ${categoryName}`}
                category={category}
                total={totalArea}
                iconClass="fa-solid fa-check-double"
              />
              <ProductList
                items={filteredExtraItems}
                estimateId={estimateId}
                onAddProduct={handleAddProduct}
                onRemoveProduct={handleRemoveProduct}
                onProductUpdate={handleProductUpdate}
                isLocked={isLocked}
                category={category}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EstimateCategory;
