import React from "react";
import './Footer.scss';
import { LanguageSelect } from "../LanguageSelect";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { currentVersion } from "../../utils/currentVersion";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer pb-5 mb-0 columns has-background-light mt-6">
      <div className="column content">
        <h4>
          Informacje
        </h4>
        <p>
          <Link to="/terms-of-service">Regulamin</Link>
        </p>
        <p>
          <Link to="/privacy-policy">Polityka prywatności i cookies</Link>
        </p>
        <p>
          <Link to="/changelog">Historia aktualizacji</Link>
        </p>
        <p>
          <Link to="https://www.facebook.com/goestimapl" target="_blank" rel="noreferrer">
            <span className="icon has-text-grey">
              <i className="fab fa-facebook-square fa-2x"></i>
            </span>
          </Link>
        </p>
      </div>
      <div className="column content">
        <h4>
          Kontakt
        </h4>
        <p>
          <span className="icon">
            <i className="fa-solid fa-phone"></i>
          </span>
          {' '}
          <a href="tel:451261661">451 261 661</a>
        </p>
        <p>
          <span className="icon">
            <i className="fa-brands fa-whatsapp"></i>
          </span>
          {' '}
          <a href="https://api.whatsapp.com/send?phone=451261661">Wyślij wiadomość</a>
        </p>
        <p>
          <span className="icon">
            <i className="fa-regular fa-envelope"></i>
          </span>
          {' '}
          <a href="mailto:janiewiczk@gmail.com">janiewiczk@gmail.com</a>
        </p>
      </div>
      <div className="column content">
        <h4>
          Język strony
        </h4>
        <p className="is-size-7">(w trakcie realizacji)</p>
        <LanguageSelect />
        <h4>
          Baza wiedzy
        </h4>
        <Link to="/articles">Artykuły o meblarstwie</Link>
      </div>
      <div className="column content">
        <h4>
          GoEstima {currentVersion}
        </h4>
        <span className="icon">
          <i className="fa-regular fa-copyright"></i>
        </span>
        2024 - {new Date().getFullYear()} Janiewicz&apos;s
        <br />
        Krzysztof Janiewicz
        <br /><br />
        {t('footer-all-rights-reserved')}
      </div>
    </footer>
  );
};
