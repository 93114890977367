// HomePage.tsx
import React from "react";
import "./Changelog.scss";
import { currentVersion } from "../../utils/currentVersion";
import { Helmet } from "react-helmet";

export const Changelog: React.FC = () => {

  return (
    <div className="terms-of-service">
      <Helmet>
        <title>Historia aktualizacji | GoEstima</title>
        <meta name="description" content="Lista kolejnych aktualizacji kalkulatora mebli kuchennych | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/changelog" />
      </Helmet>
      <h1>Aktualizacje</h1>
      <p className="has-text-info has-text-centered">
        <span className="icon">
          <i className="fa-regular fa-thumbs-up"></i>
        </span>
        {' '}
        <span>
          Aktualna wersja aplikacji GoEstima to
        </span>
        {' '}
        <span className="has-text-weight-bold">
          {currentVersion}
        </span>
      </p>

      <section>
        <h2>13.09.2024 | v0.1.7</h2>
        <ul>
          <li>
            Poprawiono wygląd listy wycen.
          </li>
          <li>
            Poprawiono szybkość ładowania obrazków w sekcji EDYCJA PRODUKTÓW.
          </li>
          <li>
            Ogólne poprawki usprawniające.
          </li>
        </ul>
      </section>

      <section>
        <h2>13.09.2024 | v0.1.6</h2>
        <ul>
          <li>
            Dodano wyszukiwarkę produktów w każdej kategorii.
          </li>
        </ul>
      </section>

      <section>
        <h2>12.09.2024 | v0.1.5</h2>
        <ul>
          <li>
            WAŻNE: od teraz aplikacja w zakładce FRONTY wyświetla powierzchnię dodanych korpusów w zakładce KORPUSY.
          </li>
        </ul>
      </section>

      <section>
        <h2>10.09.2024 | v0.1.4</h2>
        <ul>
          <li>
            Dodano możliwość zablokowania wyceny, aby przypadkowo nie zmienić lub nie usunąć wyceny, która jest ważna / ostateczna.
          </li>
          <li>
            Dodano sekcję artykułów o meblarstwie (link w stopce).
          </li>
        </ul>
      </section>

      <section>
        <h2>04.09.2024 | v0.1.3</h2>
        <ul>
          <li>
            Dodanie danych firmy do wydruku wyceny.
          </li>
          <li>
            Kosmetyczne poprawki layoutu.
          </li>
          <li>
            Poprawiono szybkość ładowania obrazków produktów.
          </li>
          <li>
            Poprawiono wyświetlanie przycisków RABAT, DRUKUJ WYCENĘ, ZAPISZ PDF na urządzeniach mobilnych.
          </li>
        </ul>
      </section>

      <section>
        <h2>28.08.2024 | v0.1.2</h2>
        <ul>
          <li>
            Dodano opcję rabatu w podsumowaniu wyceny.
          </li>
          <li>
            Dodano opcję wyświetlania cen w podsumowaniu: tylko suma, suma i podsumy kategorii, wszystkie ceny jednostkowe.
          </li>
        </ul>
      </section>
    </div>
  );
};
