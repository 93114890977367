import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../Header';
import { Footer } from '../Footer';

export const Layout: React.FC = () => (
  <div className="container">
    <Header />
    <div className="main">
      <Outlet />
    </div>
    <Footer />
  </div>
);
