// src/data/articleList.ts

export interface ArticleInfo {
  slug: string;
  title: string;
  description: string;
  imageUrl: string;
}

export const articleList: ArticleInfo[] = [
  {
    slug: "rewolucja-w-branzy-meblarskiej",
    title: "Rewolucja w branży meblarskiej: Kalkulator mebli kuchennych online",
    description: "Poznaj, jak kalkulatory online rewolucjonizują proces wyceny mebli kuchennych.",
    imageUrl: "/articles/kalkulator-mebli.jpg"
  },
  // Dodaj więcej artykułów w miarę ich tworzenia
];
